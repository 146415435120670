import React from 'react';
import {Container} from 'react-bootstrap';

import '../../styles/style.scss';

class StandoutSection extends React.Component {

  render() {
    return (
      <Container className="section standout">{this.props.children}</Container>
    )
  }

}
export default StandoutSection;