import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import Heading from '../../components/heading/heading';
import { Row, Col, Button } from 'react-bootstrap';
import StandoutSection from '../../components/section/standoutsection';


//import './benefitscontent.scss';

class PricingContent extends React.Component {
  render() {
    //const SPAN = 8;
    //const LEFT = 0;
    //const RIGHT = 4;
    const { t } = this.props;

    return (
      <>
      <Row>
      <Col>


    <Heading as="h1" className="page">{t('Pricing._headline')}</Heading>

    
      <StandoutSection>
<p>{t('Pricing.tagline')}</p>
</StandoutSection>


<Heading as="h2">{t('Pricing.what_headline')}</Heading>
<Trans i18nKey='Pricing.what'>
<p>Yes. You can plan your meals, manage your workouts, create your recipes and ingredients, track your intake, monitor your energy expenditure for free.</p>
</Trans>

<Heading as="h2">{t('Pricing.why_headline')}</Heading>
<Trans i18nKey='Pricing.why'>
<p>
We want to develop a tool that is useful for all athletes of all levels and bring information about proper nutrition to everyone who care about their bodies and their athletic performance.
This is what is important to us.
</p>
</Trans>
<Heading as="h2">{t('Pricing.data_headline')}</Heading>
<Trans i18nKey='Pricing.data'>

<p>
There’s a quote that is attributed to Andrew Lewis: <em>“If you’re not paying, you’re not the customer; you’re the product being sold.”</em>
</p><p>
(For a very complete discussion about the origins of this idea and the quote in particular <a href="https://quoteinvestigator.com/2017/07/16/product/" target="_blank" rel="noopener noreferrer">go here</a>. If you’d rather know what that means for Nutrfy and you, don’t get distracted and continue reading)
</p>
<p>
Our mission is to make you successful, and we believe that we our product will bring value into your life. We want to build our business and our community around this mission. So, no: We will not sell your personal data. To anyone.
</p>
</Trans>

<Heading as="h2">{t('Pricing.future_headline')}</Heading>
<Trans i18nKey='Pricing.future'>
<p>
Ain’t gonna lie to you, there might be changes in the future. At the moment, we are focused on bringing you the best experience when using Nutrfy.
</p>
<p>
At some point, we might introduce products that you can purchase or subscribe to. But there will always be a free version of Nutrfy that provides value to all athletes. <b>Promised.</b>
</p>
</Trans>
    </Col>
    </Row>



    <Row>
<Col>
<div style={{marginTop: "8rem", marginBottom: "8rem"}}>
    
    <div className="text-center">
      <div className="join-intro">{t('fragments.convinced_question')}</div>
      <Button href="https://app.nutrfy.com/signup" target="_blank" rel="noopener noreferrer" variant="primary" size="lg">
    {t('components.button_join')}</Button>
    </div>

    
  </div>
  </Col>
</Row>

</>
    )
  }
}
export default withTranslation()(PricingContent);